import "./App.css";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  hidden: {
    display: "none",
  },
  avatar: {
    borderRadius: "50%",
  },
  "@keyframes stepper": {
    "0%": { bottom: 225 },
    "50%": { bottom: 125 },
    "100%": { bottom: 225 },
  },
  foot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    animationName: "$stepper",
    animationDuration: "1s",
    animationTimingFunction: "ease",
    animationIterationCount: "infinite",
    animationDelay: "0.5s",
  },
}));

function App() {
  const classes = useStyles();
  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    // increase count every 1 second
    setTimeout(function () {
      setInterval(() => {
        setCount((prev) => prev + 1);
      }, 1000);
    }, 500);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Button
          className={isAuthenticated && classes.hidden}
          variant="contained"
          color="primary"
          onClick={() => loginWithRedirect()}
        >
          Log in with Discord
        </Button>
        {isAuthenticated && (
          <div className={classes.container}>
            <div className={classes.foot}>
              <img
                src="https://cdn.discordapp.com/avatars/608572511977865246/a_68c6a75fe2f7528dacee8b79ab9f8ee5.png"
                alt={user.name}
                className={classes.avatar}
                width="100px"
                style={{ marginBottom: -25 }}
              />
              <img
                src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/facebook/230/foot_1f9b6.png"
                alt="Foot"
                width="150px"
              />
            </div>
            <img
              src={user.picture}
              alt={user.name}
              className={classes.avatar}
              width="100px"
              style={{ marginBottom: 10 }}
            />
            <Typography>Hi {user.name},</Typography>
            <Typography>You have been stepped on by frix:</Typography>
            <Typography>
              {count} time{count === 1 ? "" : "s"}
            </Typography>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
